<template>
  <dash-page-new
      v-if="!templateId"
  >
    <template #pre_header.wide>
      <div style="width: 100%; position:relative;" class="d-flex align-center justify-space-between px-4 py-2">
        <div class="d-flex">
          <v-icon class="mr-2">mdi-school-outline</v-icon>
        </div>
        <h5 class="mr-16" >{{ navigationCourseItem ? navigationCourseItem.text : '' }}</h5>
        <div>
          <!-- Language -->
          <ft-select
              @input="changeLanguage"
              :value="selectedLang"
              :disabled="languagesSelect.length === 0 "
              :items="languagesSelect"
          >
            <v-hover #default="{hover}">
              <ws-tooltip :text="$t('ChangePageLanguage')">
                <v-btn
                    :style="hover ? `background-color : ${wsACCENT + '66'}` : null"
                    light
                    :color="wsDARKER"
                    class="pl-1 mr-1 pr-0 noCaps text-no-wrap"
                    height="30"
                    min-width="30"
                    text
                >
                      <span style="color : white">
                            {{ selectedLang.toUpperCase() }}
                      </span>
                  <v-icon color="white">mdi-menu-down</v-icon>

                </v-btn>
              </ws-tooltip>
            </v-hover>
          </ft-select>
        </div>
      </div>

    </template>

    <template #default>
      <div
          class="
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

        <v-sheet  max-width="700">
          <div v-if="coursePages.length === 0">

            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsDARKLIGHT" >mdi-palette</v-icon>
            </div>


            <h2 :style="`color : ${wsDARKER}`" class="text-center  mt-3">
              {{ $t('avalon.courses.blank_course.title') }}
            </h2>
            <h4 :style="`color : ${wsDARKER}`"  class="text-center font-weight-regular mt-3  mb-6">
              {{ $t('avalon.courses.blank_course.text') }}
            </h4>

            <div class="d-flex justify-center">
              <ws-button
                  @click="$store.commit('avalonPage/OPEN_NEW_PAGE',course)"
                  label="avalon.courses.blank_course.add_course_page"
                  class="ml-2"
              />
            </div>
            <v-sheet color="red" width="600" class="d-flex justify-center">


            </v-sheet>


          </div>
          <div v-else>
            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsDARKLIGHT" >mdi-translate-variant</v-icon>
            </div>
            <h3 style="font-size: 22px; font-weight: 700" class="text-center  mt-6">
              {{ $t('avalon.courses.blank_course.title_no_language') }}
            </h3>
            <h4  class="text-center font-weight-regular  mb-6">
              {{ $t('avalon.courses.blank_course.existing_languages.text') }}
            </h4>

            <div class="d-flex justify-center mt-8">
              <ws-button
                  @click="copyLangPage"
                  label="CreateNewPage"
                  outlined
                  class="mr-2"
              />
              <ft-select
                  v-if="coursePages.length > 0"
                  @input="copyLangPage($event)"
                  :items="coursePages"
              >
                <ws-button
                    :click-stop="coursePages.length < 1"
                    label="CopyPage"
                />

              </ft-select>
            </div>

          </div>

        </v-sheet>

      </div>
    </template>

    <template #dialog>

      <avalon-page-template-selector-dialog
          v-if="displayTemplatesDialog"
          :value="true"
          @close="displayTemplatesDialog = false"
          @back="displayTemplatesDialog = false"
          @select="setTemplate"
      />
      <!-- Languages Selector Dialog-->
      <avalon-language-selector
          v-if="displayLangSettingsDialog"
          v-model="displayLangSettingsDialog"
      />

    </template>



  </dash-page-new>
  <avalonPageEditor
      v-else
      course-auto-template
      :page="templateId" />

</template>

<script>
import { mapState , mapActions } from "vuex";
import avalonPageEditor from "@/components/AvalonEditor/Editor/avalonPageEditor";
import avalonPageTemplateSelectorDialog from "@/components/AvalonEditor/Dialogs/Page/avalonPageTemplateSelectorDialog";
import avalonLanguageSelector from "@/components/AvalonEditor/settings/UI/avalonLanguageSelector";

export default {
  name: "avalonCoursePage",
  components : {
    avalonPageTemplateSelectorDialog,
    avalonPageEditor,
    avalonLanguageSelector
  },
  props : {
    course : {
      type : String
    }
  },
  data() {
    return {
      pageAlias : null,
      displayAutoTemplateDialog : false,
      displayTemplatesDialog : false,
      displayLangSettingsDialog : false,
      aliasError : false,
      templateId : null
    }
  },
  computed : {
    ...mapState('avalon' , [ 'coursesSelect' , 'selectedLang']),

    languagesSelect() {
      let items = []

      this.AVALON_LANGS.forEach(lang=>{
        items.push({text: lang.toUpperCase(), value: lang})
      })

      items.push({ text : this.$t('avalon.language.lang_settings') , value : 'settings' })

      return items
    },
    navigationCourseItem() {
      let item = this.coursesSelect.find(el=> el.value === this.course)
      if ( !item ) {
        return null
      }
      return item
    },
    coursePages() {
      if (!this.navigationCourseItem) {
        return null
      }
      let pages = []

      Object.keys(this.navigationCourseItem.page).forEach(lang=>{
        if ( this.navigationCourseItem.page[lang] ) {
          pages.push({ text : lang.toUpperCase() , value : this.navigationCourseItem.page[lang] })
        }
      })
      return pages
    }
  },
  watch : {
    course() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('avalon' , [
        'SET_COURSE_TEMPLATE' ,
        'GET_COURSE_TEMPLATE'
    ]),
    ...mapActions('avalonPage',[
      'ADD_PAGE'
    ]),

    async copyLangPage(pageId = null) {
      let data = {
        lang : this.selectedLang,
        title : this.navigationCourseItem.text,
        name :  this.navigationCourseItem.text,
        alias : this.navigationCourseItem.page_alias,
        course : this.course
      }

      if ( pageId ) {
        data.id = pageId
      }

      let result = await this.ADD_PAGE(data)

      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }

      this.$router.push(this.businessPublicLink('page/' + result.id + '/editor'))

    },

    changeLanguage(lang) {
      if (lang === 'settings') {
        this.displayLangSettingsDialog = true
        return
      }
      this.$store.state.avalon.selectedLang = lang

        setTimeout(()=> {

          let pageValue = null

          let courseNavigationItem = this.$store.state.avalon.coursesSelect.find(el => el.value === this.course)
          if ( courseNavigationItem && courseNavigationItem.page && courseNavigationItem.page[lang]) {
            pageValue = courseNavigationItem.page[lang]
          } else {
            pageValue = this.course + '/course'
          }

          this.$router.push(this.businessPublicLink('page/' + pageValue + '/editor'))

        } , 100)

    },

    openAutoTemplates() {
      this.displayAutoTemplateDialog = true
      this.autoTemplate = true

    },
    autoTemplateNext() {
      this.displayAutoTemplateDialog = false
      this.displayTemplatesDialog = true
    },
    setTemplate(templateId) {
      if ( this.autoTemplate ) {

        let data = {
          template_id : templateId,
          course_id : this.course,
          alias : this.pageAlias
        }
        this.notify(data)

        let result = this.SET_COURSE_TEMPLATE(data)
        if ( !result ) {
          return this.notify(this.$t('NetworkError') , 'error')
        }

        this.notify(this.$t('ChangesSaved'))
        this.displayTemplatesDialog = false


      } else {
        this.notify(templateId)
      }
    },
    copyCoursePage(id) {
     // this.notify('CopyCoursePage' + id)
      console.log(id)
    },
    async initPage() {
      let result = await this.GET_COURSE_TEMPLATE({course : this.course , lang : this.selectedLang})

      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.templateId = result.template_id
      if (this.templateId ) {
        this.$store.state.avalon.course = result.course
      }
    }
  },
  mounted() {
    this.$store.state.avalon.sideMenu = true
    this.initPage()
  }
}
</script>

<style scoped>

</style>