<template>
  <portal to="dialog" :disabled="!display">
    <v-fade-transition>
      <v-sheet
          v-if="display"
          :color="wsACCENT + 'bc' "
          style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 999999999999999 ;"
          class="d-flex align-center justify-center"
      >

        <v-sheet
            light
            @click.stop
            width="1000"
            :class="[
              {'fill-height '  :   fullscreen || SM || fillHeight  },
              {'wsRoundedHalf' : !(fullscreen || SM) },
          ]"
            height="90%"
            class="wsRoundedHalf d-flex "
            elevation="5"
        >
          <!-- Navigation-->
          <v-sheet width="300" :color="wsBACKGROUND" class="overflow-y-auto wsRoundedHalfLeft">

            <h2 :style="`color : ${wsDARKER}`" class="pa-6 py-4 "> {{ $t(title) }}</h2>

            <div class="px-2">
              <ws-button
                  @click="$emit('select')"
                  label="AddEmptyPage"
                  block
                  text
                  left

              />
            </div>




            <ws-avalon-navigation-list
                v-model="selectedCategory"
                :items="categoriesSelect"
                mandatory
                no-edit
            />


          </v-sheet>
          <!-- Blocks -->
          <v-sheet
              width="700"
              class="fill-height overflow-y-auto  d-flex flex-column wsRoundedHalfRight "
          >
            <!-- Header -->
            <div class="d-flex align-center pa-3 px-6 mb-2 justify-space-between">
<!--              <div class="d-flex">-->

<!--                <v-sheet-->
<!--                    @click="gridView = false"-->
<!--                    :color="!gridView ? (wsATTENTION + '44') : 'transparent' "-->
<!--                    class="d-flex align-center justify-center wsRoundedLight pointer"-->
<!--                    height="34"-->
<!--                    width="34"-->
<!--                    icon-->
<!--                    v-ripple-->
<!--                >-->
<!--                  <v-icon size="20" :color="!gridView ? wsATTENTION : wsACCENT">mdi-view-agenda-outline</v-icon>-->
<!--                </v-sheet>-->

<!--                <v-sheet-->
<!--                    @click="gridView = true"-->
<!--                    :color="gridView ? (wsATTENTION + '44') : 'transparent' "-->
<!--                    class="d-flex align-center justify-center wsRoundedLight pointer"-->
<!--                    height="34"-->
<!--                    width="34"-->
<!--                    icon-->
<!--                    v-ripple-->
<!--                >-->
<!--                  <v-icon :color="gridView ? wsATTENTION : wsACCENT" size="20">mdi-grid-large</v-icon>-->
<!--                </v-sheet>-->


<!--              </div>-->

              <h3 :style="`color : ${wsDARKER}`" class="mt-3"> {{ $t('ChoosePageTemplate') }}</h3>

              <v-btn :color="wsDARKER" icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </div>
            <!-- Blocks -->

            <div class="overflow-y-auto px-6 pb-6" style="height: 100%">


              <div @click="$emit('select' , page.id)"  v-for="(page , index) in items" :key="page.id">
                <preview-page
                    :class="index !== 0 ? 'mt-6' : null"
                    :page="page.id"
                    width="653"
                />
              </div>

            </div>
            <!-- Buttons -->
            <div class="d-flex justify-end px-6 py-5  justify-end">
              <ws-button
                  @click="$emit('back')"
                  label="GoBack"
                  outlined
                  class="mr-3"
              />
<!--              <ws-button-->
<!--                  @click="$emit('select')"-->
<!--                  label="Choose"-->
<!--              />-->
            </div>



          </v-sheet>




        </v-sheet>


      </v-sheet>
    </v-fade-transition>
  </portal>

</template>

<script>
import {mapActions , mapState} from "vuex";

import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
import previewPage from "@/components/AvalonEditor/Templates/previewPage";

export default {
  name: "avalonNewBlockDialog",
  components : {
    previewPage,
    wsAvalonNavigationList,
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    number : {
      type : Number ,
      default : 0
    },
    footer : {
      type : Boolean,
      default : false
    },
    title : {
      type : String,
      default : 'AddPage'
    }
  },
  data() {
    return {
      templates : [],
      entityData : {},
      display : false,
      selectedCategory : 1,
      gridView : false,
      categories : []
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),

    items() {
      return this.getCategoryPages(this.selectedCategory)
    },
    categoriesSelect() {
      let items = this.categories

      if ( this.getCategoryPages('saved').length > 0 ) {
        items.unshift({ text : this.$t('OwnPagesTemplates') , rightIcon : 'mdi-bookmark-outline' , value : 'saved' , action : 'zero' , noEdit : true} )
      }

      return items
    },
    blocksFiltered() {
      let items = this.blocks
      if ( items.length === 0 ) {
        return []
      }
      if ( this.footer ) {
        items = items.filter(el=> el.is_footer)
      }

      return items
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input', this.display)
      }
    },
    number() {
      this.entityData = {
        number : this.number
      }
    }
  },
  methods : {
    ...mapActions('avalon',['GET_TEMPLATE_BLOCKS' , 'GET_TEMPLATE_PAGES']),

    getCategoryPages(category) {
      return this.templates.filter( el=> el.category.toString() === category.toString() )
    },

    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },

  },
  async mounted() {
    if ( this.value ) {
      this.display = this.value
    }

    let result = await this.GET_TEMPLATE_PAGES(this.selectedLang)
    if ( !result || result === true ) {
      return
    }
    this.categories = result.categories
    this.selectedCategory = result.categories[0].value
    this.templates = result.templates

  }
}
</script>

<style scoped>

</style>